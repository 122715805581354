@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap");

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: "Titillium Web", sans-serif;
}

:root {
	--mainColor: #ffffff;
	--mainColorLight: #5767aa;
	--secondaryColor: #db2b39;
	--textColor: #e30022;
}

header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 80px;
	padding: 0 2rem;
	color: var(--textColor);
}



header .nav-btn {
	padding: 5px;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: var(--textColor);
	visibility: hidden;
	opacity: 0;
	font-size: 1.8rem;
}

header div,
nav {
	display: flex;
	align-items: center;
}

@media only screen and (max-width: 1024px) {
	header .nav-btn {
		visibility: visible;
		opacity: 1;
	}

	header nav {
		position: fixed;
		top: -100vh;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 1.5rem;
		transition: 1s;
	}

	header .responsive_nav {
		transform: translateY(100vh);
	}

}


/*---------------------------- Custom Code ---------------------------------*/
.logoDiv{
    /* float: left; */
    text-decoration: none;
    display: flex;
    flex-direction: row;
}
.logoName{

    color: #460C68;
    /* margin-left: 10px; */
    padding-left: 10px;
    font-family: Raleway-Bold;
    padding-top: 5px;
}
.AppLogo{
    padding-left: 1vw;
    padding-right: 1vw;
    width: 62px;
    height: 32px;
}




.BtnDiv {

    float:right;
    /* margin-left: 67vw; */
    display: flex;
    flex: row;
    justify-content: end;
}
/* @media (max-width: 480px){
    .BtnDiv{
        scale: 0;
    }
} */
.all-game-btn{
    font-family: Raleway-ExtraBold;
    font-size: .8rem;
    display: grid;
    align-items: center;
    padding-right: 4vw;
    width: auto;
    height: auto;
    text-decoration: none;
    color: black;
}
.lets-talk-btn{
    border-radius: 5px;
    display: grid;
    align-items: center;
    font-family: Raleway-ExtraBold;
    background-color: #460C68;
    color: white;
    font-size: .8rem;
    padding-left: 1vw;
    padding-right: 1vw;
    width: auto;
    height: auto;
    text-decoration: none;
}



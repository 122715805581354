.lets-talk-main-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: #1e1c27; */
    /* color:  white; */
    width: 100vw;
    height: 100vh;
    
    margin: auto;
}
.lets-talk-main-center{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #1e1c27;
    color:  white;
    width: 80vw;
    height: 80vh;
    border-radius: 20px;    
}
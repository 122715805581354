@import url(https://fonts.googleapis.com/css2?family=Lilita+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.index-resume-div {
  /* width: 80vw; */
  /* height: 200vh; */
  /* background-color: black; */
  /* padding-top: 10vh; */
  /* padding-bottom: 10vh; */
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
  color: white;
}

@font-face {
  font-family: Raleway-Thin;
  src: url(/static/media/Raleway-Thin.db8e7354.ttf);
}

@font-face {
  font-family: Raleway-ExtraLight;
  src: url(/static/media/Raleway-ExtraLight.550ad10f.ttf);
}

@font-face {
  font-family: Raleway-Light;
  src: url(/static/media/Raleway-Light.ed645c2b.ttf);
}

@font-face {
  font-family: Raleway-Regular;
  src: url(/static/media/Raleway-Regular.20d41940.ttf);
}

@font-face {
  font-family: Raleway-Medium;
  src: url(/static/media/Raleway-Medium.b952c3c8.ttf);
}

@font-face {
  font-family: Raleway-SemiBold;
  src: url(/static/media/Raleway-SemiBold.87641f99.ttf);
}

@font-face {
  font-family: Raleway-Bold;
  src: url(/static/media/Raleway-Bold.88079335.ttf);
}

@font-face {
  font-family: Raleway-ExtraBold;
  src: url(/static/media/Raleway-ExtraBold.27f7ef17.ttf);
}

@font-face {
  font-family: Raleway-Black;
  src: url(/static/media/Raleway-Black.35e0e2e7.ttf);
}



@font-face {
  font-family: Ubuntu-SemiBold;
  src: url(/static/media/Ubuntu-Bold.896a6021.ttf);
}

@font-face {
  font-family: Ubuntu-Bold;
  src: url(/static/media/Ubuntu-Light.c9d68f61.ttf);
}

@font-face {
  font-family: Ubuntu-ExtraBold;
  src: url(/static/media/Ubuntu-Medium.d3c3b35e.ttf);
}

@font-face {
  font-family: Ubuntu-Black;
  src: url(/static/media/Ubuntu-Regular.84ea7c5c.ttf);
}

.index-center-align {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}

body {
  overflow-x: hidden;
}



/* font-family: 'Lilita One', cursive; */
/* font-family: 'Poppins', sans-serif; */
/* font-family: 'Roboto', sans-serif; */
/* .Header-background {

} */

.title-background{
    padding-top: 3vh;
    /* border:1px solid black; */
    display: flex;
    /* align-items: center;
    justify-content: center; */
    flex-direction: row;
}

.title-div-start{
    margin-left: 5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    /* border: 2px solid red; */
}
.title-div-middle{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-left: 15vw;

}
.title-div-end{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-left: 13vw;

}
.title-div-middle-text{
    margin-left: 5vw;
    margin-right: 5vw;
    color: white;
    font-family: 'Poppins', sans-serif;
}
.logo-text1{
    margin-right: 5px;
    font-size: 2rem;
    color: #58CBF9;
    font-family: 'Lilita One', cursive;
}
.logo-text2{
    margin-left: 5px;
    font-size: 2rem;
    color: white;
    font-family: 'Lilita One', cursive;
}
.logo-dot{
    margin-top: 5px;
    border-radius: 50px;
    background-color: #58CBF9;
    width: 10px;
    height: 10px;
}
.logo-dot-custom{
    margin-top: 5px;
    border-radius: 50px;
    background-color: #58CBF9;
    width: 7px;
    height: 7px;
}


.title-get-in-touch-button{
    background-color: #FBA41B;
    padding: 15px;
    font-size: 0.9rem;
    font-family: 'Roboto', sans-serif;
    color: #FFFFFF;
}
.red-underline{
    width: 0px;
    height: 1px;
    background-color: #E30022;
    transition:  10s;
    /* scale: 0.5; */
}
.title-div-middle-text:hover .red-underline{
    transition:  25s;
    cursor: pointer;
    width: auto;
    scale: 1;
}

@media (max-width: 480px){
    .title-div-middle{
        scale: 0;
    
    }
    .title-div-end{
        scale: 0;
    }
}
/* font-family: 'Roboto', sans-serif; */
.homepage-background{
    margin-top: 2vh;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;

}
.controller-image{
    align-items: center;
    justify-content: center;
    -webkit-animation: bottop-top 01s;
            animation: bottop-top 01s;
    width: 60vw;
    z-index: 4;

}
.homepage-text{
    z-index: 6;
    font-family: 'Lilita One', cursive;
    color: white;
    font-size: 3rem;
    -webkit-animation: left-come 1s;
            animation: left-come 1s;
}
@-webkit-keyframes left-come{
    0%{
        /* margin-right: 15vw; */
        opacity: 0;
    }
    50%{
        /* scale: 0.5; */
    }
    100%{
        margin-right: 0;
        opacity: 1;
    }
}
@keyframes left-come{
    0%{
        /* margin-right: 15vw; */
        opacity: 0;
    }
    50%{
        /* scale: 0.5; */
    }
    100%{
        margin-right: 0;
        opacity: 1;
    }
}
@-webkit-keyframes bottop-top{
    0%{
        margin-top: 10vw;
        opacity: 0;
    }
    25%{
        margin-top: 10vw;
        opacity: 0;
    }
    100%{
        margin-top: 0;
        opacity: 1;
    }
}
@keyframes bottop-top{
    0%{
        margin-top: 10vw;
        opacity: 0;
    }
    25%{
        margin-top: 10vw;
        opacity: 0;
    }
    100%{
        margin-top: 0;
        opacity: 1;
    }
}


@media (max-width: 480px){
    .homepage-background{
        margin-top: 20vh;
       
    }
}
/* font-family: 'Lato', sans-serif; */
/* font-family: 'Roboto', sans-serif; */
.introduction-background{
    margin: auto;
    width: 90vw;
    padding-bottom: 7vh;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border:1px solid rgba(255, 255, 255, 0.18);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
    border-radius: 20px;
}
.introduction-main-div{
    margin-left: 10vw;
    margin-right: 10vw;
    padding-top: 5vh;
    display: flex;
    color: white;
    
}
.introduction-name-div{
    /* margin-right: 30vw; */
    -webkit-animation : 1s introduction-left-right;
            animation : 1s introduction-left-right;
}

@-webkit-keyframes introduction-left-right {
    0%{
        margin-left: -20vh;
        opacity: 0;
    }
    100%{
        margin-left: 0vh;
        opacity: 1;
    }
}

@keyframes introduction-left-right {
    0%{
        margin-left: -20vh;
        opacity: 0;
    }
    100%{
        margin-left: 0vh;
        opacity: 1;
    }
}
.introduction-bar{
    width: 3vw;
    height: 5px;
    background-color: #FBA41B;
}
.introduction-welcome-text{
    color: #FBA41B;
    font-family: 'Lato', sans-serif;
    font-size: 2.5rem;
}
.introduction-custom-text{
    margin-top: 3vh;
    font-size: 0.9rem;
    font-family: 'Roboto', sans-serif;
    width: 30vw;
}

.introduction-detail-div{
    /* padding-left: 20vw; */
    margin-left: 8vw;
    /* margin-right: 18vw; */
    width: 40vw;
}
.introduction-detail-inner-div{
    margin-top: 4vh;
    /* margin-left: -20vw; */
    /* border: 2px solid white; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.introduction-detail-image{
    width: 100px;
    height: 100px;
}
.introduction-detail-inner-div-right{
    margin-left: 2vw;
}

.introduction-detail-title{
    color: #FBA41B;
    font-family: 'Lato', sans-serif;
    font-size: 1rem;
    

}
.introduction-detail-discription{
    margin-top: 1vh;
    font-size: 0.9rem;
    font-family: 'Roboto', sans-serif;

}


.introduction-verticle-line-1,.introduction-verticle-line-2,.introduction-verticle-line-3{
    opacity: 0.2;
    position: absolute;
    background-color: white;
    width: 1px;
    height: 50vh;
    

  }
  .introduction-verticle-line-1{
    margin-left: 20vw;
  }
  .introduction-verticle-line-2{
    margin-left: 40vw;
  }
  .introduction-verticle-line-3{
    margin-left: 60vw;
  }

  @media (max-width: 480px){
    .introduction-main-div{
        flex-direction: column;
    }
    
    .introduction-background{
        height: auto;
    }
    .introduction-detail-div{
        margin-left: 0vw;
        width: auto;
    
    }
    .introduction-detail-image{
        width: 0vw;
        height: 0vh;
    }
    .introduction-custom-text{
        width: auto;
    }
    
}
 
:root{
    --primary-color:#f1f1f1;
    --primary-color-light:#DAFFFB;
    --secondary-color:#132043;
    --secondary-color-light:#FFB07F;

    --page-color-1:#DA0C81;
    --page-color-2:#ffffff;
}
/* font-family: 'Work Sans', sans-serif; */
.footer-background{
    background-color: var(--secondary-color);
    width: 100%;
    padding-top: 2vh;
    padding-bottom: 2vh;
}
.footer-right-text{
    color: white;
    text-align: center;
    font-family: 'Work Sans', sans-serif;
    font-size: 0.8rem;
}
.TitleDiv {
    width: 90vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #ffffff7d;
    margin-top: 30px;
    border-radius: 70px;
    /* padding: 1rem; */
    padding-left: 1rem;
    padding-right: 1rem;
    position: relative;
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    position: fixed;
    z-index: 10;
    -webkit-backdrop-filter: blur(100px);
    backdrop-filter: blur(100px);
    transition: 0.3s;
}

.logoDiv {
    text-decoration: none;
    display: flex;
    flex-direction: row;
}

.logoName {
    color: #460C68;
    font-family: Raleway-Bold;
}

.AppLogo {
    width: 32px;
    height: 32px;
}

.title-div-desktop {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

}

.title-div-btn {
    text-decoration: none;
    padding-left: 20px;
    padding-right: 20px;
}

.title-div-btn-text {
    font-family: Raleway-ExtraBold;
    font-size: .8rem;
    display: grid;
    align-items: center;
    text-decoration: none;
    color: black;
}

/* Mobile UI */
.title-div-mobile-show-btn {}

.title-div-mobile-ui-div {

    z-index: 20;
    background-color: #181818;
}

.title-div-btn-text-in {
    font-family: Raleway-ExtraBold;
    font-size: 1rem;
    display: grid;
    align-items: center;
    color: #460C68;
}

.title-div-mobile-ui-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    outline: 1px solid rebeccapurple;
    background-color: black;
}

@media (max-width: 900px) {
    .title-div-desktop {
        display: none;
    }

    .TitleDiv {
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 9px;
        padding-bottom: 9px;
        align-items: center;
        justify-content: center;
    }

    .AppLogo {
        display: none;
    }

    .logoDiv {
        align-items: center;
        justify-content: center;
    }
}

@media (min-width:480px) {}
.HomePageDiv {
    padding-top: 30vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* outline: 1px solid rebeccapurple; */
    /* height: 100vh; */
    width: 100vw;
}

.firstContainer {
    display: flex;
    flex-direction: column;
    padding-left: 10vw
}

.person-name {
    /* color: #E90064; */
    color: var(--page-color-1);
    font-family: Raleway-ExtraBold;
    font-size: 2rem;
    margin-bottom: 10px;
}

.description-name {
    /* color: #460C68; */
    color: var(--secondary-color);
    font-family: Raleway-Bold;
    word-wrap: break-word;
    display: flex;
    white-space: nowrap;
    flex-direction: row;
    flex-wrap: nowrap;
    font-size: 4rem;
}

.extraButton {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 70px;
}

.square {
    display: grid;
    align-items: center;
    font-size: 2rem;
    background-color: var(--page-color-1);
    -webkit-clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 25%, 75% 0);
    clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 25%, 75% 0);
    width: 50px;
    height: 50px;
}

.arrow-text {
    padding: 7px;
    /* padding:10px; */
    font-family: Raleway-ExtraBold;
    color: white;
}

.circle-block {
    display: grid;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    border-radius: 50%;
    /* background-color: #460C68; */
    background-color: var(--secondary-color);
    width: 50px;
    height: 50px;
}

.github {
    width: 30px;
    height: 30px;
}

.line-draw {
    /* margin-left: 1vw; */
    background-color: #460C68;
    opacity: 20%;
    width: 250px;
    height: 2px;
}

.SecondContainer {
    /* margin-left: 20vw; */
}

.computerImage {
    width: 40vw;
}

.bottom-line {
    position: absolute;
    /* width: 90vw; */
    height: 0.4px;
    opacity: 20%;
    background-color: #460C68;
    margin-top: 70vh;
}

@media (max-width: 600px) {
        .HomePageDiv {
            padding-top: 10vh;
            flex-direction: column-reverse;
            align-items: center;
            justify-content: center;
        }
        .firstContainer{
            padding-left: 0px;
        }
        .circle-block {
            margin-left: 5vw;
        }

        .description-name {
            font-size: 2rem;
        }

        .person-name {
            font-size: 2rem;
        }
        .computerImage{
            width: 70vw;
        }


        .SecondContainer {
            margin-left: 0vw;
        }

        .bottom-line {
            margin-top: 100vh;
        }
}




.WhatIDo {
    width: 100vw;
    padding-top: 20vh;
    padding-bottom: 20vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--page-color-2);
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    overflow: hidden;
}

.first-div-main {
    /* padding-top: 10vh;
    padding-left: 4vh;
    padding-right: 4vh; */
    padding: 20px;
    /* outline: 3px solid cyan; */
    /* margin: 10px; */
    /* outline:  2px solid rebeccapurple; */

}

.first-div {
    display: flex;
    justify-self: flex-end;
    border-radius: 10px;
    background-color: var(--secondary-color);
    width: 35vw;
    /* padding-bottom: 20px; */
    /* outline: 3px solid red; */

}

.title-first-div {
    font-size: 4rem;
    color: white;
    font-family: Raleway-ExtraBold;
}

.description-first-div {
    font-size: 1rem;
    color: white;
    padding-top: 3vh;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.learn-more {
    font-size: 0.8rem;
    padding-top: 3vh;
    color: white;
    font-family: Raleway-ExtraBold;
}

.bottom-border-learn {
    padding-top: 0.2vh;
    background-color: white;
    width: 5vw;
    height: 1px;
}

/* ------------ Second Div --------------- */

.second-div {
    height: inherit;
    display: flex;
    justify-self: flex-start;

}

.second-div-main {

    margin-left: 5vw;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    height: 100%;
}

.display-engine {
    margin-bottom: 1vh;
    background-color: #00000010;
    border-radius: 10px;
    width: 30vw;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* transition: 0.3s; */
    margin-top: 5.5vh;
    margin-bottom: 5.5vh;
}

.display-engine:hover {
    background-color: var(--secondary-color);
    /* transition: 0.2s; */
}

.engine-logo {
    margin-right: 1vw;
    border-radius: 50%;
    background-color: #151E2D;
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    margin-left: 1vw;
    /* transition: 0.2s; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.engine-logo img {
    object-fit: cover;
    width: 80%;
    height: 50%;
}

.display-engine:hover .engine-logo {
    background-color: #FFFFFF;
    /* transition: 0.2s; */
}

.engine-title {
    /* transition: 0.2s; */
    font-size: 1.5rem;
    /* color: #151E2D; */
    color: #FFFFFF;
    font-family: Raleway-ExtraBold;
}

.display-engine:hover .engine-title {
    color: #FFFFFF;
    /* transition: 0.2s; */
}

.engine-description {
    /* transition: 0.2s; */
    font-size: .8rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    /* color: #151E2D; */
    color: #FFFFFF;
}

.display-engine:hover .engine-description {
    /* transition: 0.2s; */
    color: #FFFFFF;
}

.engine-logo-png {
    /* width: 27px; */
    /* transition: 0.2s; */
    /* height: 27px; */
}

.display-engine:hover .engine-logo-png {
    /* transition: 0.2s; */
    -webkit-filter: brightness(1) invert(1);
            filter: brightness(1) invert(1);
}

@media (max-width: 980px) {
    .WhatIDo {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .first-div {
        margin-left: 20px;
        margin-right: 20px;
        width: 90vw;
    }

    .bottom-border-learn {
        width: 18vw;
    }

    .title-first-div {
        font-size: 2rem;
    }

    .description-first-div {
        font-size: 0.8rem;

    }

    .second-div-main {
        margin-left: 0vw;
        margin-top: 5vh;
    }

    .display-engine {
        width: 90vw;
        height: 12vh;
    }
}

.WhatIDo-circle-patch-1 {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    right: 5vh;
}

.WhatIDo-circle-patch-2 {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    right: 20vh;
    bottom: 3vh;
}
.WhoIWorkWithMain {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(255, 0, 98, 0.25) 0px 54px 55px, rgba(255, 0, 98, 0.12) 0px -12px 30px, rgba(255, 0, 98, 0.12) 0px 4px 6px, rgba(255, 0, 98, 0.17) 0px 12px 13px, rgba(255, 0, 98, 0.09) 0px -3px 5px;
}

.WhoIWorkWith-static {
    background-color: var(--page-color-1);
    width: 100vw;
}

.columnContainer {
    color: white;
    display: flex;
    flex-direction: column;
    padding: 7%;
}

.headerText {
    /* color: white; */
    font-family: Raleway-ExtraBold;
    /* font-family: monospace; */
    color: var(--primary-color);
    font-size: 1rem;
}

.headerDefine {
    font-family: Raleway-ExtraBold;
    font-size: 3.5rem;
    white-space: nowrap;
    /* word-break: keep-all; */
    /* word-wrap: no; */

}

.headerDescription {
    font-family: Roboto-Thin;
    padding-top: 10px;
    font-family: monospace;
    /* width: 30vw; */
}

.ludo-image {
    /* outline: 2px solid red; */
    padding: 1%;
    width: 300px;

}

@media (max-width: 900px) {
    .WhoIWorkWithMain {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 1%;
        padding-right: 1%;
    }

    .headerText {
        font-size: 0.8rem;
        padding-bottom: 10px;
    }

    .headerDefine {
        font-size: 1.5rem;
    }

    .ludo-image {
        padding: 1%;
        width: 250px;
        height: 250px;

    }
}

.WhoIWorkWithMain-image-bg {
    background-color: black;
    width: 100%;
    position: absolute;
    top: 0px;
    border-radius: 12px;
}

@media (max-width: 900px) {
    .WhoIWorkWithMain-image-bg {
        background-color: black;
        width: auto;
        height: 100%;
        position: absolute;
        top: 0px;
        border-radius: 12px;
    }
}
.Resume-div-main{
    width: 90vw;
    /* background-color: #1e1c27; */
    background-color: var(--secondary-color);
    border-radius: 10px;
    margin-bottom: 10vh;
    margin-top: 15vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5%;
    overflow: hidden;

}
.Resume-title-div{
    display: flex;
    flex-direction: row;
}

.Resume-profile-picture{
    height: 200px;
    width: 200px;
    background-color: #ffad01;
    border-radius: 8px;
}
.Resume-profile-Description-main{
    padding-left: 5vw;
    padding-right: 5vw;
    color: white;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
}
.Resume-Description-Name{
    font-size: 3rem;
    font-family: Raleway-Bold;
}
.Resume-Description-Post{
    font-size: 1.3rem;
    font-family: Raleway-SemiBold;
    white-space: nowrap;
    color: #53cfd8;
}
.Resume-Description-About{
    padding-top: 3vh;
    font-family: Raleway-Regular;

}
.Resume-div-divideLine{
    width: 90vw;
    height: 0.5px;
    opacity: 0.3;
    background-color: white;
    margin-top: 5vh;
    margin-bottom: 5vh;
}


.Resume-div-second-container-main{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* align-items: center; */
    /* justify-content: center; */
}
.Resume-div-second-container-1{
    /* width: 40vw; */
    /* outline: 1px solid rebeccapurple; */
    /* justify-content: space-around; */
}
.Resume-div-second-container-2{

}
.Resume-div-second-container-3{
    width: 40vw;
}
.Resume-div-second-container-title{
    font-family: Raleway-Bold;
    color: #53cfd8;
    padding-top: 10vh;
}
.Resume-div-second-container-1-description{
    color: black;
}
.Resume-div-second-container-1-contact-div-contact-info{
    padding-top: 3vh;
    display: flex;
    flex-direction: row;
}
.Resume-div-second-container-1-contact-div-contact-info-icon{
    width: 25px;
    height: 25px;
}
.Resume-div-second-container-1-contact-div-contact-info-detail{
    padding-left: 0.5vw;
    color: white;
    font-family: Ubuntu-Light;
    display: flex;
    align-items: center;
}
.Resume-div-work-experience-content{
    display: flex;
    flex-direction: row;
    /* width: auto; */
}
.Resume-div-dot{
    margin-top: 3vh;
    width: 0;
	height: 0;
	border-top: 7px solid transparent;
	border-left: 14px solid #ffffff;
	border-bottom: 7px solid transparent;
    margin-right: 1vw;
    background-color: #53cfd8;
    border-radius: 50%;
}
.Resume-div-details-title{
    display: flex;
    flex-direction: row;
    padding-top: 3vh;
    color: white;
    font-family: Raleway-Bold;
}
.Resume-div-details-date{
    padding-top: 0.8vh;
    color: white;
    font-family: Raleway-Medium;
}
.Resume-div-details-description{
    padding-top: 1vh;
    color: white;
    font-family: Raleway-Light;
    /* padding-right: 10vw; */
}
@media (max-width: 750px){

    .Resume-title-div{
        flex-direction: column;
        align-items: center;
    }
    .Resume-profile-Description-main{
        padding-top: 3vh;
        padding-left: 0vw;
        padding-right: 0vw;
        align-items: center;
        text-align: center;
    }
    .Resume-div-second-container-1{
        width: 70vw;
    }
    .Resume-div-second-container-2{
    
    }
    .Resume-div-second-container-3{
        width: 70vw;
    }
    .Resume-div-second-container-main{
        display: flex;
        flex-direction: column;
    }
    
}
.static-color-main-bg {
    /* background-color: #d21460; */
    background-color: var(--primary-color);
    width: 100%;
    height: 300%;
    position: fixed;
    z-index: -10;
}

.static-color-white-1,
.static-color-white-2 {
    width: 10vw;
    height: 200%;
    bottom: -4%;
    background-color: var(--primary-color-light);
    position: fixed;
    left: 60%;
    rotate: -10deg;
}
.static-color-white-2{
    left: 72%;
}
.engine-logo-main {
    background-color: var(--primary-color);
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 50px;
}

.engine-logo {
    margin-left: 10vw;
    margin-right: 10vw;
    width: 150px;
    height: 150px;
}
.mainDiv{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.lets-talk-main-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: #1e1c27; */
    /* color:  white; */
    width: 100vw;
    height: 100vh;
    
    margin: auto;
}
.lets-talk-main-center{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #1e1c27;
    color:  white;
    width: 80vw;
    height: 80vh;
    border-radius: 20px;    
}
.all-games-main{
    /* background-color: red; */
    margin-top: 15vh;
    width: 90vw;
    display: grid;
    margin-bottom: 15vh;
    /* outline: 1px     solid black; */
}
.all-games-form{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px,1fr));
    grid-gap: 10px;
    /* justify-items: center; */
    align-items: center;
    grid-row-gap: 30px;
    row-gap: 30px;
    
}
.all-games-btn-main{
    width: 350px;
    height: 300px;
    /* background-color: black; */
    /* outline: 1px solid cyan; */
    /* border-radius: 20px; */
    /* overflow: hidden; */
}   
.all-games-btn-second-image{
    width: 350;
    height: 220px;
    border-radius: 20px;

    /* background-color: green; */
}
.all-games-btn-second-description-main{
    width: 350px;
    height: 80px;
    /* background-color: white; */
    display: flex;
    justify-content: center;
}
.all-games-btn-second-container{
    width: 350px;
    display: flex;
    flex-direction: row;
}
.all-games-btn-second-icon{
    border-radius: 10px;
    width: 64px;
    height: 64px;
}
.all-games-btn-second-description-text{
    width: 280px;
    height: 20px;
    padding-left: 20px;
}
.all-games-btn-second-description-name{
    font-family: Raleway-ExtraBold;

}
.all-games-btn-second-description-rating{
  font-family: Raleway-Regular;
    
}
.all-games-btn-second-description-category{
  font-family: Raleway-Regular;
    
}
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: "Titillium Web", sans-serif;
}

:root {
	--mainColor: #ffffff;
	--mainColorLight: #5767aa;
	--secondaryColor: #db2b39;
	--textColor: #e30022;
}

header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 80px;
	padding: 0 2rem;
	color: #e30022;
	color: var(--textColor);
}



header .nav-btn {
	padding: 5px;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: #e30022;
	color: var(--textColor);
	visibility: hidden;
	opacity: 0;
	font-size: 1.8rem;
}

header div,
nav {
	display: flex;
	align-items: center;
}

@media only screen and (max-width: 1024px) {
	header .nav-btn {
		visibility: visible;
		opacity: 1;
	}

	header nav {
		position: fixed;
		top: -100vh;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		grid-gap: 1.5rem;
		gap: 1.5rem;
		transition: 1s;
	}

	header .responsive_nav {
		-webkit-transform: translateY(100vh);
		        transform: translateY(100vh);
	}

}


/*---------------------------- Custom Code ---------------------------------*/
.logoDiv{
    /* float: left; */
    text-decoration: none;
    display: flex;
    flex-direction: row;
}
.logoName{

    color: #460C68;
    /* margin-left: 10px; */
    padding-left: 10px;
    font-family: Raleway-Bold;
    padding-top: 5px;
}
.AppLogo{
    padding-left: 1vw;
    padding-right: 1vw;
    width: 62px;
    height: 32px;
}




.BtnDiv {

    float:right;
    /* margin-left: 67vw; */
    display: flex;
    flex: row 1;
    justify-content: end;
}
/* @media (max-width: 480px){
    .BtnDiv{
        scale: 0;
    }
} */
.all-game-btn{
    font-family: Raleway-ExtraBold;
    font-size: .8rem;
    display: grid;
    align-items: center;
    padding-right: 4vw;
    width: auto;
    height: auto;
    text-decoration: none;
    color: black;
}
.lets-talk-btn{
    border-radius: 5px;
    display: grid;
    align-items: center;
    font-family: Raleway-ExtraBold;
    background-color: #460C68;
    color: white;
    font-size: .8rem;
    padding-left: 1vw;
    padding-right: 1vw;
    width: auto;
    height: auto;
    text-decoration: none;
}




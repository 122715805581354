@import url("../StaticColor.css");
.Resume-div-main{
    width: 90vw;
    /* background-color: #1e1c27; */
    background-color: var(--secondary-color);
    border-radius: 10px;
    margin-bottom: 10vh;
    margin-top: 15vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5%;
    overflow: hidden;

}
.Resume-title-div{
    display: flex;
    flex-direction: row;
}

.Resume-profile-picture{
    height: 200px;
    width: 200px;
    background-color: #ffad01;
    border-radius: 8px;
}
.Resume-profile-Description-main{
    padding-left: 5vw;
    padding-right: 5vw;
    color: white;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
}
.Resume-Description-Name{
    font-size: 3rem;
    font-family: Raleway-Bold;
}
.Resume-Description-Post{
    font-size: 1.3rem;
    font-family: Raleway-SemiBold;
    white-space: nowrap;
    color: #53cfd8;
}
.Resume-Description-About{
    padding-top: 3vh;
    font-family: Raleway-Regular;

}
.Resume-div-divideLine{
    width: 90vw;
    height: 0.5px;
    opacity: 0.3;
    background-color: white;
    margin-top: 5vh;
    margin-bottom: 5vh;
}


.Resume-div-second-container-main{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* align-items: center; */
    /* justify-content: center; */
}
.Resume-div-second-container-1{
    /* width: 40vw; */
    /* outline: 1px solid rebeccapurple; */
    /* justify-content: space-around; */
}
.Resume-div-second-container-2{

}
.Resume-div-second-container-3{
    width: 40vw;
}
.Resume-div-second-container-title{
    font-family: Raleway-Bold;
    color: #53cfd8;
    padding-top: 10vh;
}
.Resume-div-second-container-1-description{
    color: black;
}
.Resume-div-second-container-1-contact-div-contact-info{
    padding-top: 3vh;
    display: flex;
    flex-direction: row;
}
.Resume-div-second-container-1-contact-div-contact-info-icon{
    width: 25px;
    height: 25px;
}
.Resume-div-second-container-1-contact-div-contact-info-detail{
    padding-left: 0.5vw;
    color: white;
    font-family: Ubuntu-Light;
    display: flex;
    align-items: center;
}
.Resume-div-work-experience-content{
    display: flex;
    flex-direction: row;
    /* width: auto; */
}
.Resume-div-dot{
    margin-top: 3vh;
    width: 0;
	height: 0;
	border-top: 7px solid transparent;
	border-left: 14px solid #ffffff;
	border-bottom: 7px solid transparent;
    margin-right: 1vw;
    background-color: #53cfd8;
    border-radius: 50%;
}
.Resume-div-details-title{
    display: flex;
    flex-direction: row;
    padding-top: 3vh;
    color: white;
    font-family: Raleway-Bold;
}
.Resume-div-details-date{
    padding-top: 0.8vh;
    color: white;
    font-family: Raleway-Medium;
}
.Resume-div-details-description{
    padding-top: 1vh;
    color: white;
    font-family: Raleway-Light;
    /* padding-right: 10vw; */
}
@media (max-width: 750px){

    .Resume-title-div{
        flex-direction: column;
        align-items: center;
    }
    .Resume-profile-Description-main{
        padding-top: 3vh;
        padding-left: 0vw;
        padding-right: 0vw;
        align-items: center;
        text-align: center;
    }
    .Resume-div-second-container-1{
        width: 70vw;
    }
    .Resume-div-second-container-2{
    
    }
    .Resume-div-second-container-3{
        width: 70vw;
    }
    .Resume-div-second-container-main{
        display: flex;
        flex-direction: column;
    }
    
}
@import url("./StaticColor.css");
.static-color-main-bg {
    /* background-color: #d21460; */
    background-color: var(--primary-color);
    width: 100%;
    height: 300%;
    position: fixed;
    z-index: -10;
}

.static-color-white-1,
.static-color-white-2 {
    width: 10vw;
    height: 200%;
    bottom: -4%;
    background-color: var(--primary-color-light);
    position: fixed;
    left: 60%;
    rotate: -10deg;
}
.static-color-white-2{
    left: 72%;
}
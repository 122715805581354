@import url("./StaticColor.css");
.HomePageDiv {
    padding-top: 30vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* outline: 1px solid rebeccapurple; */
    /* height: 100vh; */
    width: 100vw;
}

.firstContainer {
    display: flex;
    flex-direction: column;
    padding-left: 10vw
}

.person-name {
    /* color: #E90064; */
    color: var(--page-color-1);
    font-family: Raleway-ExtraBold;
    font-size: 2rem;
    margin-bottom: 10px;
}

.description-name {
    /* color: #460C68; */
    color: var(--secondary-color);
    font-family: Raleway-Bold;
    word-wrap: break-word;
    display: flex;
    white-space: nowrap;
    flex-direction: row;
    flex-wrap: nowrap;
    font-size: 4rem;
}

.extraButton {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 70px;
}

.square {
    display: grid;
    align-items: center;
    font-size: 2rem;
    background-color: var(--page-color-1);
    -webkit-clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 25%, 75% 0);
    clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 25%, 75% 0);
    width: 50px;
    height: 50px;
}

.arrow-text {
    padding: 7px;
    /* padding:10px; */
    font-family: Raleway-ExtraBold;
    color: white;
}

.circle-block {
    display: grid;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    border-radius: 50%;
    /* background-color: #460C68; */
    background-color: var(--secondary-color);
    width: 50px;
    height: 50px;
}

.github {
    width: 30px;
    height: 30px;
}

.line-draw {
    /* margin-left: 1vw; */
    background-color: #460C68;
    opacity: 20%;
    width: 250px;
    height: 2px;
}

.SecondContainer {
    /* margin-left: 20vw; */
}

.computerImage {
    width: 40vw;
}

.bottom-line {
    position: absolute;
    /* width: 90vw; */
    height: 0.4px;
    opacity: 20%;
    background-color: #460C68;
    margin-top: 70vh;
}

@media (max-width: 600px) {
        .HomePageDiv {
            padding-top: 10vh;
            flex-direction: column-reverse;
            align-items: center;
            justify-content: center;
        }
        .firstContainer{
            padding-left: 0px;
        }
        .circle-block {
            margin-left: 5vw;
        }

        .description-name {
            font-size: 2rem;
        }

        .person-name {
            font-size: 2rem;
        }
        .computerImage{
            width: 70vw;
        }


        .SecondContainer {
            margin-left: 0vw;
        }

        .bottom-line {
            margin-top: 100vh;
        }
}




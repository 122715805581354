@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
/* font-family: 'Lato', sans-serif; */
/* font-family: 'Roboto', sans-serif; */
.introduction-background{
    margin: auto;
    width: 90vw;
    padding-bottom: 7vh;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border:1px solid rgba(255, 255, 255, 0.18);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
    border-radius: 20px;
}
.introduction-main-div{
    margin-left: 10vw;
    margin-right: 10vw;
    padding-top: 5vh;
    display: flex;
    color: white;
    
}
.introduction-name-div{
    /* margin-right: 30vw; */
    animation : 1s introduction-left-right;
}

@keyframes introduction-left-right {
    0%{
        margin-left: -20vh;
        opacity: 0;
    }
    100%{
        margin-left: 0vh;
        opacity: 1;
    }
}
.introduction-bar{
    width: 3vw;
    height: 5px;
    background-color: #FBA41B;
}
.introduction-welcome-text{
    color: #FBA41B;
    font-family: 'Lato', sans-serif;
    font-size: 2.5rem;
}
.introduction-custom-text{
    margin-top: 3vh;
    font-size: 0.9rem;
    font-family: 'Roboto', sans-serif;
    width: 30vw;
}

.introduction-detail-div{
    /* padding-left: 20vw; */
    margin-left: 8vw;
    /* margin-right: 18vw; */
    width: 40vw;
}
.introduction-detail-inner-div{
    margin-top: 4vh;
    /* margin-left: -20vw; */
    /* border: 2px solid white; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.introduction-detail-image{
    width: 100px;
    height: 100px;
}
.introduction-detail-inner-div-right{
    margin-left: 2vw;
}

.introduction-detail-title{
    color: #FBA41B;
    font-family: 'Lato', sans-serif;
    font-size: 1rem;
    

}
.introduction-detail-discription{
    margin-top: 1vh;
    font-size: 0.9rem;
    font-family: 'Roboto', sans-serif;

}


.introduction-verticle-line-1,.introduction-verticle-line-2,.introduction-verticle-line-3{
    opacity: 0.2;
    position: absolute;
    background-color: white;
    width: 1px;
    height: 50vh;
    

  }
  .introduction-verticle-line-1{
    margin-left: 20vw;
  }
  .introduction-verticle-line-2{
    margin-left: 40vw;
  }
  .introduction-verticle-line-3{
    margin-left: 60vw;
  }

  @media (max-width: 480px){
    .introduction-main-div{
        flex-direction: column;
    }
    
    .introduction-background{
        height: auto;
    }
    .introduction-detail-div{
        margin-left: 0vw;
        width: auto;
    
    }
    .introduction-detail-image{
        width: 0vw;
        height: 0vh;
    }
    .introduction-custom-text{
        width: auto;
    }
    
}
 
@import url("./StaticColor.css");

.WhoIWorkWithMain {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(255, 0, 98, 0.25) 0px 54px 55px, rgba(255, 0, 98, 0.12) 0px -12px 30px, rgba(255, 0, 98, 0.12) 0px 4px 6px, rgba(255, 0, 98, 0.17) 0px 12px 13px, rgba(255, 0, 98, 0.09) 0px -3px 5px;
}

.WhoIWorkWith-static {
    background-color: var(--page-color-1);
    width: 100vw;
}

.columnContainer {
    color: white;
    display: flex;
    flex-direction: column;
    padding: 7%;
}

.headerText {
    /* color: white; */
    font-family: Raleway-ExtraBold;
    /* font-family: monospace; */
    color: var(--primary-color);
    font-size: 1rem;
}

.headerDefine {
    font-family: Raleway-ExtraBold;
    font-size: 3.5rem;
    white-space: nowrap;
    /* word-break: keep-all; */
    /* word-wrap: no; */

}

.headerDescription {
    font-family: Roboto-Thin;
    padding-top: 10px;
    font-family: monospace;
    /* width: 30vw; */
}

.ludo-image {
    /* outline: 2px solid red; */
    padding: 1%;
    width: 300px;

}

@media (max-width: 900px) {
    .WhoIWorkWithMain {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 1%;
        padding-right: 1%;
    }

    .headerText {
        font-size: 0.8rem;
        padding-bottom: 10px;
    }

    .headerDefine {
        font-size: 1.5rem;
    }

    .ludo-image {
        padding: 1%;
        width: 250px;
        height: 250px;

    }
}

.WhoIWorkWithMain-image-bg {
    background-color: black;
    width: 100%;
    position: absolute;
    top: 0px;
    border-radius: 12px;
}

@media (max-width: 900px) {
    .WhoIWorkWithMain-image-bg {
        background-color: black;
        width: auto;
        height: 100%;
        position: absolute;
        top: 0px;
        border-radius: 12px;
    }
}
.all-games-main{
    /* background-color: red; */
    margin-top: 15vh;
    width: 90vw;
    display: grid;
    margin-bottom: 15vh;
    /* outline: 1px     solid black; */
}
.all-games-form{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px,1fr));
    grid-gap: 10px;
    /* justify-items: center; */
    align-items: center;
    row-gap: 30px;
    
}
.all-games-btn-main{
    width: 350px;
    height: 300px;
    /* background-color: black; */
    /* outline: 1px solid cyan; */
    /* border-radius: 20px; */
    /* overflow: hidden; */
}   
.all-games-btn-second-image{
    width: 350;
    height: 220px;
    border-radius: 20px;

    /* background-color: green; */
}
.all-games-btn-second-description-main{
    width: 350px;
    height: 80px;
    /* background-color: white; */
    display: flex;
    justify-content: center;
}
.all-games-btn-second-container{
    width: 350px;
    display: flex;
    flex-direction: row;
}
.all-games-btn-second-icon{
    border-radius: 10px;
    width: 64px;
    height: 64px;
}
.all-games-btn-second-description-text{
    width: 280px;
    height: 20px;
    padding-left: 20px;
}
.all-games-btn-second-description-name{
    font-family: Raleway-ExtraBold;

}
.all-games-btn-second-description-rating{
  font-family: Raleway-Regular;
    
}
.all-games-btn-second-description-category{
  font-family: Raleway-Regular;
    
}
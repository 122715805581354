@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');
/* font-family: 'Lilita One', cursive; */
/* font-family: 'Poppins', sans-serif; */
/* font-family: 'Roboto', sans-serif; */
/* .Header-background {

} */

.title-background{
    padding-top: 3vh;
    /* border:1px solid black; */
    display: flex;
    /* align-items: center;
    justify-content: center; */
    flex-direction: row;
}

.title-div-start{
    margin-left: 5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    /* border: 2px solid red; */
}
.title-div-middle{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-left: 15vw;

}
.title-div-end{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-left: 13vw;

}
.title-div-middle-text{
    margin-left: 5vw;
    margin-right: 5vw;
    color: white;
    font-family: 'Poppins', sans-serif;
}
.logo-text1{
    margin-right: 5px;
    font-size: 2rem;
    color: #58CBF9;
    font-family: 'Lilita One', cursive;
}
.logo-text2{
    margin-left: 5px;
    font-size: 2rem;
    color: white;
    font-family: 'Lilita One', cursive;
}
.logo-dot{
    margin-top: 5px;
    border-radius: 50px;
    background-color: #58CBF9;
    width: 10px;
    height: 10px;
}
.logo-dot-custom{
    margin-top: 5px;
    border-radius: 50px;
    background-color: #58CBF9;
    width: 7px;
    height: 7px;
}


.title-get-in-touch-button{
    background-color: #FBA41B;
    padding: 15px;
    font-size: 0.9rem;
    font-family: 'Roboto', sans-serif;
    color: #FFFFFF;
}
.red-underline{
    width: 0px;
    height: 1px;
    background-color: #E30022;
    transition:  10s;
    /* scale: 0.5; */
}
.title-div-middle-text:hover .red-underline{
    transition:  25s;
    cursor: pointer;
    width: auto;
    scale: 1;
}

@media (max-width: 480px){
    .title-div-middle{
        scale: 0;
    
    }
    .title-div-end{
        scale: 0;
    }
}
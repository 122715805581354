.TitleDiv {
    width: 90vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #ffffff7d;
    margin-top: 30px;
    border-radius: 70px;
    /* padding: 1rem; */
    padding-left: 1rem;
    padding-right: 1rem;
    position: relative;
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    position: fixed;
    z-index: 10;
    -webkit-backdrop-filter: blur(100px);
    backdrop-filter: blur(100px);
    transition: 0.3s;
}

.logoDiv {
    text-decoration: none;
    display: flex;
    flex-direction: row;
}

.logoName {
    color: #460C68;
    font-family: Raleway-Bold;
}

.AppLogo {
    width: 32px;
    height: 32px;
}

.title-div-desktop {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

}

.title-div-btn {
    text-decoration: none;
    padding-left: 20px;
    padding-right: 20px;
}

.title-div-btn-text {
    font-family: Raleway-ExtraBold;
    font-size: .8rem;
    display: grid;
    align-items: center;
    text-decoration: none;
    color: black;
}

/* Mobile UI */
.title-div-mobile-show-btn {}

.title-div-mobile-ui-div {

    z-index: 20;
    background-color: #181818;
}

.title-div-btn-text-in {
    font-family: Raleway-ExtraBold;
    font-size: 1rem;
    display: grid;
    align-items: center;
    color: #460C68;
}

.title-div-mobile-ui-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    outline: 1px solid rebeccapurple;
    background-color: black;
}

@media (max-width: 900px) {
    .title-div-desktop {
        display: none;
    }

    .TitleDiv {
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 9px;
        padding-bottom: 9px;
        align-items: center;
        justify-content: center;
    }

    .AppLogo {
        display: none;
    }

    .logoDiv {
        align-items: center;
        justify-content: center;
    }
}

@media (min-width:480px) {}
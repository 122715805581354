@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');
/* font-family: 'Roboto', sans-serif; */
.homepage-background{
    margin-top: 2vh;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;

}
.controller-image{
    align-items: center;
    justify-content: center;
    animation: bottop-top 01s;
    width: 60vw;
    z-index: 4;

}
.homepage-text{
    z-index: 6;
    font-family: 'Lilita One', cursive;
    color: white;
    font-size: 3rem;
    animation: left-come 1s;
}
@keyframes left-come{
    0%{
        /* margin-right: 15vw; */
        opacity: 0;
    }
    50%{
        /* scale: 0.5; */
    }
    100%{
        margin-right: 0;
        opacity: 1;
    }
}
@keyframes bottop-top{
    0%{
        margin-top: 10vw;
        opacity: 0;
    }
    25%{
        margin-top: 10vw;
        opacity: 0;
    }
    100%{
        margin-top: 0;
        opacity: 1;
    }
}


@media (max-width: 480px){
    .homepage-background{
        margin-top: 20vh;
       
    }
}
@import url("./StaticColor.css");
.WhatIDo {
    width: 100vw;
    padding-top: 20vh;
    padding-bottom: 20vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--page-color-2);
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    overflow: hidden;
}

.first-div-main {
    /* padding-top: 10vh;
    padding-left: 4vh;
    padding-right: 4vh; */
    padding: 20px;
    /* outline: 3px solid cyan; */
    /* margin: 10px; */
    /* outline:  2px solid rebeccapurple; */

}

.first-div {
    display: flex;
    justify-self: flex-end;
    border-radius: 10px;
    background-color: var(--secondary-color);
    width: 35vw;
    /* padding-bottom: 20px; */
    /* outline: 3px solid red; */

}

.title-first-div {
    font-size: 4rem;
    color: white;
    font-family: Raleway-ExtraBold;
}

.description-first-div {
    font-size: 1rem;
    color: white;
    padding-top: 3vh;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.learn-more {
    font-size: 0.8rem;
    padding-top: 3vh;
    color: white;
    font-family: Raleway-ExtraBold;
}

.bottom-border-learn {
    padding-top: 0.2vh;
    background-color: white;
    width: 5vw;
    height: 1px;
}

/* ------------ Second Div --------------- */

.second-div {
    height: inherit;
    display: flex;
    justify-self: flex-start;

}

.second-div-main {

    margin-left: 5vw;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    height: 100%;
}

.display-engine {
    margin-bottom: 1vh;
    background-color: #00000010;
    border-radius: 10px;
    width: 30vw;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* transition: 0.3s; */
    margin-top: 5.5vh;
    margin-bottom: 5.5vh;
}

.display-engine:hover {
    background-color: var(--secondary-color);
    /* transition: 0.2s; */
}

.engine-logo {
    margin-right: 1vw;
    border-radius: 50%;
    background-color: #151E2D;
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    margin-left: 1vw;
    /* transition: 0.2s; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.engine-logo img {
    object-fit: cover;
    width: 80%;
    height: 50%;
}

.display-engine:hover .engine-logo {
    background-color: #FFFFFF;
    /* transition: 0.2s; */
}

.engine-title {
    /* transition: 0.2s; */
    font-size: 1.5rem;
    /* color: #151E2D; */
    color: #FFFFFF;
    font-family: Raleway-ExtraBold;
}

.display-engine:hover .engine-title {
    color: #FFFFFF;
    /* transition: 0.2s; */
}

.engine-description {
    /* transition: 0.2s; */
    font-size: .8rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    /* color: #151E2D; */
    color: #FFFFFF;
}

.display-engine:hover .engine-description {
    /* transition: 0.2s; */
    color: #FFFFFF;
}

.engine-logo-png {
    /* width: 27px; */
    /* transition: 0.2s; */
    /* height: 27px; */
}

.display-engine:hover .engine-logo-png {
    /* transition: 0.2s; */
    filter: brightness(1) invert(1);
}

@media (max-width: 980px) {
    .WhatIDo {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .first-div {
        margin-left: 20px;
        margin-right: 20px;
        width: 90vw;
    }

    .bottom-border-learn {
        width: 18vw;
    }

    .title-first-div {
        font-size: 2rem;
    }

    .description-first-div {
        font-size: 0.8rem;

    }

    .second-div-main {
        margin-left: 0vw;
        margin-top: 5vh;
    }

    .display-engine {
        width: 90vw;
        height: 12vh;
    }
}

.WhatIDo-circle-patch-1 {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    right: 5vh;
}

.WhatIDo-circle-patch-2 {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    right: 20vh;
    bottom: 3vh;
}
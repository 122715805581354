body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.index-resume-div {
  /* width: 80vw; */
  /* height: 200vh; */
  /* background-color: black; */
  /* padding-top: 10vh; */
  /* padding-bottom: 10vh; */
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
  color: white;
}

@font-face {
  font-family: Raleway-Thin;
  src: url(./res/fonts/Raleway/static/Raleway-Thin.ttf);
}

@font-face {
  font-family: Raleway-ExtraLight;
  src: url(./res/fonts/Raleway/static/Raleway-ExtraLight.ttf);
}

@font-face {
  font-family: Raleway-Light;
  src: url(./res/fonts/Raleway/static/Raleway-Light.ttf);
}

@font-face {
  font-family: Raleway-Regular;
  src: url(./res/fonts/Raleway/static/Raleway-Regular.ttf);
}

@font-face {
  font-family: Raleway-Medium;
  src: url(./res/fonts/Raleway/static/Raleway-Medium.ttf);
}

@font-face {
  font-family: Raleway-SemiBold;
  src: url(./res/fonts/Raleway/static/Raleway-SemiBold.ttf);
}

@font-face {
  font-family: Raleway-Bold;
  src: url(./res/fonts/Raleway/static/Raleway-Bold.ttf);
}

@font-face {
  font-family: Raleway-ExtraBold;
  src: url(./res/fonts/Raleway/static/Raleway-ExtraBold.ttf);
}

@font-face {
  font-family: Raleway-Black;
  src: url(./res/fonts/Raleway/static/Raleway-Black.ttf);
}



@font-face {
  font-family: Ubuntu-SemiBold;
  src: url(./res/fonts/Ubuntu/Ubuntu-Bold.ttf);
}

@font-face {
  font-family: Ubuntu-Bold;
  src: url(./res/fonts/Ubuntu/Ubuntu-Light.ttf);
}

@font-face {
  font-family: Ubuntu-ExtraBold;
  src: url(./res/fonts/Ubuntu/Ubuntu-Medium.ttf);
}

@font-face {
  font-family: Ubuntu-Black;
  src: url(./res/fonts/Ubuntu/Ubuntu-Regular.ttf);
}

.index-center-align {
  display: flex;
  flex-direction: column;
  align-items: center;
}